import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "item"]
  static values = { cssClass: String }

  connect() {
    this.update();
  }

  toggle() {
    this.update();
  }

  update() {
    if(this.inputTarget.checked) {
      this.itemTargets.forEach(item => item.classList.remove(this.cssClassValue || "hidden"));
    } else {
      this.itemTargets.forEach(item => item.classList.add(this.cssClassValue || "hidden"));
    }
  }
}
