const createElementFromString = content => {
  const dummyElement = document.createElement('div');

  dummyElement.innerHTML = content;

  return dummyElement.firstChild;
}

window.replaceElement = (element, newContent) => {
  const newElement = createElementFromString(newContent);

  element.replaceWith(newElement);
}

window.getTodoElement = (todoId) => document.getElementById(`todo_${todoId}`);
