import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["input"]
  static values = { newValue: String }

  set(event) {
    event.preventDefault()
    this.inputTargets.forEach(target => target.value = this.newValueValue)
  }
}
