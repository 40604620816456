import { Controller } from "stimulus"


export default class extends Controller {
  static targets = ["weekdays", "every"]

  connect() {
    this.updateVisibility()
  }

  showWeekdays() {
    this.weekdaysTarget.classList.remove('hidden')
  }

  hideWeekdays() {
    this.weekdaysTarget.classList.add('hidden')
  }

  updateVisibility() {
    if(this.everyTarget.value == "week") {
      this.showWeekdays()
    } else {
      this.hideWeekdays()
    }
  }
}
