import Flatpickr from 'stimulus-flatpickr'

import { German } from 'flatpickr/dist/l10n/de.js'
import { english } from 'flatpickr/dist/l10n/default.js'

export default class extends Flatpickr {
  locales = {
    de: German,
    en: english
  };

  connect() {
    this.config = {
      ...this.config,
      locale: this.locale
    };

    super.connect();
  }

  get locale() {
    if (this.locales && this.data.has("locale")) {
      return this.locales[this.data.get("locale")];
    } else {
      return this.locales.en;
    }
  }
}
