import * as Turbo from "@hotwired/turbo";
Turbo.start();

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

import "../channels";
import "../controllers";

import "../todos_ujs.js";

import "./application.scss";
