import { Controller } from "stimulus"


export default class extends Controller {
  static targets = ["text", "projects", "projectId"]

  initialize() {
    this.areProjectsShown = false;
  }

  connect() {
    this.textTarget.focus()
  }

  parse(event) {
    let input = event.target.value

    if(input.endsWith('#')) {
      this.showProjects()
      this.areProjectsShown = true;
    } else {
      this.hideProjects()
      this.areProjectsShown = false;
    }
  }

  setProject(event) {
    let projectId = event.target.dataset.todoParserProjectId
    let projectName = event.target.dataset.todoParserProjectName

    this.setProjectTo(projectId, projectName);
  }

  setProjectTo(projectId, projectName) {
    this.projectIdTarget.value = projectId
    this.textTarget.value = this.updateProjectInText(this.textTarget.value, projectName)
    this.hideProjects()
  }

  navigateProject(event) {
    switch(event.keyCode) {
      case 38: // up
        event.preventDefault();
        this.selectPreviousProject();
        break;
      case 40: // down
        event.preventDefault();
        this.selectNextProject();
        break;
      case 13: // enter
        if(this.areProjectsShown) {
          event.preventDefault();

          let projects = this.projectsTarget.querySelectorAll('li.selected');

          if(projects.length > 0) {
            this.setProjectTo(
              projects[0].dataset.todoParserProjectId,
              projects[0].dataset.todoParserProjectName
            )
          }
        }
        break;
    }
  }

  updateProjectInText(text, projectName) {
    return text.replace(/#.*$/, `#${projectName}`)
  }

  hideProjects() {
    this.projectsTarget.classList.add("hidden")
  }

  showProjects() {
    this.projectsTarget.classList.remove("hidden")
    this.selectFirstProject();
  }

  selectFirstProject() {
    let projects = this.projectsTarget.querySelectorAll('li');

    if(projects.length > 0) {
      projects[0].classList.add('selected');
    }
  }

  selectLastProject() {
    let projects = this.projectsTarget.querySelectorAll('li');

    if(projects.length > 0) {
      projects[projects.length - 1].classList.add('selected');
    }
  }

  selectNextProject() {
    let projects = this.projectsTarget.querySelectorAll('li.selected');

    if(projects.length > 0) {
      let selected = projects[0];
      selected.classList.remove('selected');

      let nextElement = selected.nextElementSibling;

      if(nextElement) {
        nextElement.classList.add('selected');
      } else {
        this.selectFirstProject();
      }
    } else {
      this.selectFirstProject();
    }
  }

  selectPreviousProject() {
    let projects = this.projectsTarget.querySelectorAll('li.selected');

    if(projects.length > 0) {
      let selected = projects[0];
      selected.classList.remove('selected');

      let previousElement = selected.previousElementSibling;

      if(previousElement) {
        previousElement.classList.add('selected');
      } else {
        this.selectLastProject();
      }
    } else {
      this.selectFirstProject();
    }
  }
}
